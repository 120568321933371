/** fonts **/

@import url("https://fonts.googleapis.com/css?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css?family=Sintony&display=swap");
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');

p {
  font-family: "Sintony", sans-serif;
}

p.header {
  display: flex;
  font-size: 50px;
  margin: -5px;
  text-align: center;
  font-family: "Sintony", sans-serif;
}

p.summary {
  font-family: "Sintony", sans-serif;
  font-size: 18px;
}

h1 {
  font-family: "Playfair Display", serif;
}

h1.header {
  text-align: center;
  font-size: 40px;
}

h4 {
  font-family: "Sintony", sans-serif;
  font-weight: lighter;
}

h4.subheading {
  text-align: center;
  font-size: 18px;
  text-decoration: none;
}

h5 {
  font-family: "Playfair Display", "serif";
}

.link-text {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: -1vh;
  margin-top: -1vh;
}

.link-text-2 {
  margin-top: -1.5vh;
  color: black;
  text-decoration-color: black;
  text-decoration: none;
}

.small-text {
  font-size: 13px;
  font-family: "Sintony", sans-serif;
  font-weight: bold;
  text-decoration: none;
}

/**global container**/

/** for mobile phones only **/
[class *= 'col-'] {
  width: 100%;
  background-color: blue;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-wrapper {
  width: 70vw;
  height: 10vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.links-wrapper {
  display: flex;
  flex-direction: row;
}

.link-item {
  margin-left: 1vw;
}

/** for each component's container **/
.content-container {
  justify-content: flex-start;
  width: 70vw;
  margin-top: 5vh;
}