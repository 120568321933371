.page-header {
    display: flex;
    padding-bottom: 5vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -4vh;
}

.subheading-text {
    text-decoration: none;
    margin-top: -2vh;
}
.projects {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-grow: 1;
}

@media (max-width: 45em) {
    .projects {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .description {
        text-align: center;
        margin: 0 auto;
    }
}

.elements {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
}

.photo {
    display: flex;
    justify-content: center;
}

.description {
    width: 20vw;
    padding-left: 1vw;
}