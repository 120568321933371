/* body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
  } */
  
  .box {
    width: 100px;
    height: 100px;
    background: #ff1c68;
    transform-origin: 50% 50%;
  }
  