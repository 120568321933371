.splash-screen {
  padding-top: 20vh;
  padding-bottom: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  /* background: pink; */
}

.splash-gif {
  display: flex;
  width: 20rem;
  height: auto;
  padding-bottom: 0.5vh;
}


.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: blue; */
  padding-top: 20vh;
}

.summary-left {
  display: flex;
  padding-top: 20vh;
  flex-direction: row-reverse;
  background: linear-gradient(
    to right,
    #00000000 20%,
    #ff890365 20% 80%,
    #00000000 80% 100%
  );
  background-position-y: 18vh;
  background-position-x: 22vw;
  background-repeat: no-repeat;
  background-size: 50% 50%;
}

.summary-right {
  display: flex;
  padding-top: 20vh;
  flex-direction: row;
  background: linear-gradient(
    to right,
    #00000000 20%,
    #ff890365 20% 80%,
    #00000000 80% 100%
  );
  background-position-y: 18vh;
  background-position-x: 13vw;
  background-repeat: no-repeat;
  background-size: 50% 50%;
}

@media (max-width: 45em) {
  .summary-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .summary-right {
    display: flex;
    flex-direction: column;
  }

  .summary-image-left {
    padding-top: 10vh;
  }

  .summary-image-right {
    padding-top: 10vh;
  }
}

.summary-text-left {
  display: flex;
  padding-left: 3vw;
  padding-right: 10vw;
  flex-flow: column;
}

.summary-text-right {
  display: flex;
  padding-left: 10vw;
  padding-right: 3vw;
  flex-flow: column;
}

.summary-text-phone {
  display: flex;
  padding-top: 10vh;
  padding-left: 13vw;
  padding-right: 1.5vw;
  flex-flow: column;
}

.summary-image-left {
  display: flex;
  margin-top: -10vh;
  padding-left: 5vw;
}

.summary-image-right {
  display: flex;
  margin-top: -10vh;
  padding-right: 5vw;
}

.summary-image-phone {
  display: flex;
  margin-top: -10vh;
  padding-right: 10vw;
}

.summary-phone {
  display: flex;
  padding-top: 20vh;
  flex-direction: row;
  background: linear-gradient(
    to right,
    #00000000 20%,
    #ff890365 20% 80%,
    #00000000 80% 100%
  );
  background-position-y: 25vh;
  background-position-x: 13vw;
  background-repeat: no-repeat;
  background-size: 70% 40%;
}