.brief-section {
    display: flex;
    flex-direction: row;
    margin-top: -8vh;
}

@media (max-width: 45em) {
    .brief-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-img {
        display: flex;
        margin-bottom: -18vh;
        width: 50vw;
    }

    .about-header {
        display: flex;
        margin-right: -8vw;
        margin-bottom: -20vh;
    }

}

.column-one {
    display: flex;
    flex-direction: column;
    width: 35vw;
    margin-left: 9vw;
    padding-right: 5vw;
}

.column-two {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 5vw;
    margin-right: 3vw;
}

.column-text {
    margin-top: -2vh;
}

.section-split {
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
}

.expositions-right {
    display: flex;
    flex-direction: row;
    background: linear-gradient(
        to right,
        #00000000 20%,
        #ff890365 20% 80%,
        #00000000 80% 100%
      );
      background-position-y: 5vh;
      background-position-x: 20vw;
      background-repeat: no-repeat;
      background-size: 50% 50%;
}

.exposition-left {
    display: flex;
    flex-direction: row-reverse;
    background: linear-gradient(
        to right,
        #00000000 20%,
        #ff890365 20% 80%,
        #00000000 80% 100%
      );
      background-position-y: 5vh;
      background-position-x: 18vw;
      background-repeat: no-repeat;
      background-size: 50% 50%;
}
.exp-img-left {
    width: 30vw;
    margin-left: 5vw;
}

.exp-txt-right {
    padding-left: 3vw;
    margin-top: 8vh;
    width: 20vw;
    
}

.exp-img-right {
    width: 30vw;
    margin-left: 38vw;
    margin-right: 4vw;
}

.exp-txt-left {
    margin-right: -35vw;
    width: 22vw;
    margin-top: 8vh;
}

.screenshots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.screenshot-img {
    width: 22vw;
    margin-bottom: 1.3vh;
    box-shadow: 5px 8px 8px 3px rgba(0, 0, 0, 0.308)
}

.flatlay {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.flatlay-img {
    width: 70vw;
}

.comingsoon {
    display: flex;
    justify-content: center;
}
.comingsoon-img {
    width: 50%;
}

.screenshot-fridge {
    width: 22vw;
    margin-bottom: 1.3vh;
}

.about-img {
    display: flex;
    width: 40vw;
    margin-left: 1vw;
    padding-right: 5vw;
    margin-top: 13vh;
}

.about-header {
    padding-right: 2vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.about-descr {
    margin-top: 18vh;
    margin-left: -5vw;
}

.about-font {
    font-family: "Playfair Display", "serif";
    margin-top: 18vh;
    margin-left: -15vw;
    font-size: 38px;
    background: white;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.contact-info {
    display: flex;
    flex-direction: column;
    margin-top: 13vh;
    margin-left: 10vw;
    background: linear-gradient(
        to right,
        #00000000 20%,
        #ff890365 20% 80%,
        #00000000 80% 100%
      );
      background-position-y: 8vh;
      background-position-x: -1vw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
}

.contact-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2vh;
    margin-left: 2vw;
}

.icon {
    margin-right: 1vw;
}

.contact-header {
    font-family: "Playfair Display", "serif";
    margin-left: 12.5vw;
    font-size: 38px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
}